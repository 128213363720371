import { navigateTo, piniaPluginPersistedstate } from "#imports";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import useTableUrlHelpers from "~/composables/useTableUrlHelpers";
import { QUERY_KEYS } from "~/utils/queryKeys";

import type { FrontendRequestObject } from "~/src/models/utils/Api.model";

const MAX_PAGE_SIZE = 100;
export const DEFAULT_REQUEST: FrontendRequestObject = {
  page: 1,
  pageSize: 20,
  filters: [],
  meta: {
    assignment: "all",
  },
};

function convertPageSize(
  pageNo: number,
  fromPageSize: number,
  toPageSize: number
): number {
  return 1 + Math.floor(((pageNo - 1) * (fromPageSize ?? 20)) / toPageSize);
}

export const useCaseTableStore = defineStore(
  "caseTable",
  () => {
    const { getFromURL, getQueryString } = useTableUrlHelpers(
      QUERY_KEYS.Cases.list,
      DEFAULT_REQUEST
    );

    const request = ref<FrontendRequestObject>(getFromURL());
    const pagingRequest = computed(() => {
      return {
        ...request.value,
        pageSize: MAX_PAGE_SIZE,
        page: convertPageSize(
          request.value.page,
          request.value.pageSize,
          MAX_PAGE_SIZE
        ),
      };
    });
    const queryKey = computed(() => [QUERY_KEYS.Cases.list, request] as const);

    const goToCaseList = () => {
      const url = getQueryString(request.value);
      navigateTo({ path: "/cases", query: { [QUERY_KEYS.Cases.list]: url } });
    };

    function nextPage() {
      request.value = {
        ...request.value,
        page: request.value.page + 1,
      };
    }
    function previousPage() {
      request.value = {
        ...request.value,
        page: request.value.page - 1,
      };
    }

    return {
      request,
      pagingRequest,
      queryKey,
      goToCaseList,

      nextPage,
      previousPage,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.sessionStorage(),
      pick: ["request"],
    },
  }
);
